<template>
  <SimpleLayout>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form>
            <el-form-item label="注册协议">
              <br>
              <y_editor v-model="form.login_agree" eid="login"></y_editor>
            </el-form-item>
            <el-form-item label="用户协议">
              <br>
              <y_editor v-model="form.member_agree" eid="member"></y_editor>
            </el-form-item>
            <el-form-item label="隐私协议">
              <br>
              <y_editor v-model="form.privacy_agree" eid="privacy"></y_editor>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8">
          <el-form>
            <el-form-item label="强制拦截手机号">
              <el-switch v-model="form.must_phone"></el-switch>
            </el-form-item>
            <el-form-item label="系统默认昵称">
              <el-input v-model="form.default_nickname"></el-input>
            </el-form-item>
            <el-form-item label="系统默认头像">
              <y_upload_img v-model="form.default_avatar"></y_upload_img>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </SimpleLayout>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import SimpleLayout from "@/components/layouts/SimpleLayout";
import y_editor from "@/components/y-form/y-editor/y_editor";

export default {
  name: "info",
  components: {
    SimpleLayout,
    y_upload_img,
    y_editor,
  },
  data() {
    return {
      form: {
        must_phone:false,
        default_avatar:"",
        default_nickname:"",

        login_agree:"",
        member_agree:"",
        privacy_agree:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.system.setting().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.system.settingEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>